/* TAILWIND IMPORTS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* MAIN FONT */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    font-family: 'Inter', sans-serif;
  }

  h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    line-height: 1.2;
  }
}

@layer components {
  .shadow-md {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }
}